import React from "react";
import './text.css';
const Zaos2 = () => {
    return (
        <div align={'start'} className="text1">
<h2>Часть 2. «Вы — жертва,  может быть, и невинная...»</h2>
            <p className="has-text-align-right">«<em>О. Георгий мне говорил: “Вы являетесь жертвой, <br/> хотя, может
            быть, и невинной…”</em>»<em><br/> (Из интервью о. Михаила Дубовицкого)</em></p>
            <p><strong><em>В этом разделе говорится о жизни прихода Успения в Печатниках от Великой среды 1997 г. — дня
            назначения на приход вторым священником о. Михаила Дубовицкого — до 29 июня того же года.</em></strong>
            </p>
            <p><strong>СВИДЕТЕЛЬСТВА ПРИХОЖАН</strong></p>
            <p>В этом году Святое Христово Воскресение мы с семьей праздновали в храме Успения. Замечательная
            праздничная служба, множество молодых молящихся. Все было бы прекрасно, если бы не один прискорбный
            инцидент. Во время причастия я обратил внимание, что молодой священник о. Михаил причащает людей
            чрезвычайно странно. А потом ко мне подошли мои дети. Они были очень подавлены. Оказывается, о. Михаил
            причащал их абсолютно молча! Он не только не спросил их имени, но и не сказал положенного в этот момент
            торжественного «Причащается раб Божий такой-то». А ведь православная традиция подразумевает личностное
            общение с Богом в таинствах.<br/> А.Седин</p>
            <p>Всех прихожан нашего храма крайне опечалил тот странный для священника поступок, когда о. Михаил во время
            проповеди о. Георгия стал вырывать у него микрофон, перебивать его, требуя, чтобы по непонятной никому
            причине слово было предоставлено ему. Дети же мои так испугались, что с тех пор боятся ходить в храм,
            говоря, что там злой батюшка. Когда я хотела подойти с этим вопросом к о. Михаилу, он сказал записаться
            к нему на беседу, а я за все годы своей церковной жизни не встречала священников, на беседу с которыми
            надо заранее записываться. Тем более не думаю, что о. Михаил, имея крайне малый стаж священнического
            служения, был так отягощен пастырскими заботами, что не мог сразу уделить времени мне, женщине,
            обремененной детьми и старшей его по возрасту.<br/>
            Л.Лукьянова</p>
            <p>Я сегодня видела, как пожилая женщина плакала и просила его (о. Михаила — ред.), чтобы он прекратил свои
            выкрики, недостойные не только священника, но даже просто человека, который должен был бы просто уважать
            людей, и все это перед началом литургии. И вы знаете? Я увидела на лице его первый раз улыбку, улыбку
            пацана, который сделал гадость и радуется, что кто-то плачет. <br/>
            Л.Хайницкая</p>
            <p className="has-text-align-left">В субботу, 3 мая, во время литургии мы с удивлением и смущением
            наблюдали, как о. Михаил прервал свое служение, разоблачился на глазах у всего народа и покинул алтарь
            до окончания литургии. Все это тем более странно, что разоблачался о. Михаил прямо посреди алтаря и,
            можно подумать, делал это демонстративно. Наше смущение возрастает от того, что, по словам наших братьев
            и сестер, подобные действия производились о. Михаилом чуть ли не каждый день в течение светлой седмицы.
            Нам очень хотелось бы понять, что происходит в нашем храме.<br/> Д.Матвеев, Т.Матвеева</p>
            <p>Ваше преподобие! Христос воскресе!<br/>
            Мы, прихожане церкви Успения в Печатниках, просим Ваших молитв о преодолении встретившегося нам тяжелого
            искушения. Сегодня, в четверг Светлой седмицы, мы пытались побеседовать с недавно назначенным в наш храм
            вторым священником о. Михаилом. Он отказался говорить с нами, несмотря на предварительную
            договоренность, и лишь перед уходом из церкви “в виде исключения” ответил на несколько вопросов в дверях
            храма. В частности, он сказал, что собирается причащать только тех прихожан, которые будут
            исповедоваться у него. Это повергло всех стоящих в притворе (а их было не менее пятнадцати человек) в
            большое смущение. Получается, о. Михаил считает таинство покаяния недействительным, если исповедь
            совершается Вами (или кем-то из других священников), а не лично им? Мы знаем, что ни на одном приходе
            нет подобного обычая, так что остались после этих слов в тяжелом недоумении. С большим прискорбием
            услышали мы от о. Михаила и о том, что в первые же дни своего служения на приходе он Вам “высказал свое
            неудовольствие”. Нам всегда казалось, что высказывать недовольство (если бы было что-либо действительно
            вызвавшее его) — это прерогатива правящего архиерея, но никак не только что назначенного второго
            священника. После таких слов предложение о. Михаила говорить с прихожанами только по одному и с глазу на
            глаз наводит на мысль о попытке разделения общины, нарушении мира на приходе. Особенно тревожит нас
            возможное смущение многочисленных новокрещеных и других недавно пришедших к вере людей. Для многих из
            них мы были катехизаторами и хорошо знаем, как труден был их путь к Богу и в Церковь. Страшно подумать,
            что нестроения на приходе для кого-то из них могут стать непомерным искушением и привести к их уходу из
            Православной церкви.<br/>
            Просим Вас, как настоятеля, всеми возможными средствами способствовать сохранению мира на приходе и,
            если Вы найдете это необходимым, донести нашу тревогу и смущение до священноначалия.<br/>
            А.Колымагина, Б.Колымагин, М.Каячева, И.Спокойная, <br/>
            Г.Романова, В.Максимовский, И. Чернусь</p>
            <p>В начале мая я присутствовала на Божественной литургии (после этого в связи с тяжелой болезнью и смертью
            моей матери я не была в храме 2 месяца, так как не могла оставить ее и выходить из дома). То, что я
            увидела на службе, меня потрясло. Меня потрясло неприкрытое ничем желание священника о. Михаила
            (назначенного в наш храм 2-ым священником незадолго до этого) вывести настоятеля нашего храма о. Георгия
            Кочеткова из себя, спровоцировать его и тех, кто с ним служил. Он повторял за отцом Георгием освящение
            Св. Даров в алтаре. Нам было слышно, как о. Георгий говорил ему, что он нарушает каноны, а о. Михаил
            никак не реагировал, а продолжал, как будто не слышит. Он явно хотел сорвать службу. О. Михаил кричал из
            алтаря: “не трогайте меня!“ А нам было все видно, что никто к нему даже и пальцем не прикоснулся. Ведь
            он священник. Но может быть, он не понимал, что творит, хотя трудно в это поверить.<br/>
            Н.А.Плинина-Камионская</p>
            <p><strong><em>События 6 мая стали центральным моментом конфликта новоназначенного священника с общиной. Это
            неудивительно, ибо центр жизни церковной общины — таинства Крещения и Евхаристии. Публично подвергнув
            сомнению действительность таинств, о. Михаил глубоко оскорбил не только присутствовавших на службе
            прихожан, но и всех, считающих эту общину своей. Напомним, что в этот день предупрежденный накануне
            настоятелем храма о неканоничности и даже преступности произведенного несколько раз в предыдущие дни
            “переосвящения” Св. Даров о. Михаил опять три раза осенил крестным знамением Св. Дары “от себя”, после
            того, как это уже сделал настоятель. Каждый раз это происходило после предупреждения и просьбы
            прекратить бесчинство. После третьего “переосвящения” о. Георгий запретил о. Михаилу продолжать участие
            в богослужении.</em></strong></p>
            <p><strong>РАДИОПЕРЕДАЧА “ПРАВОСЛАВНАЯ ОБЩИНА”</strong><br/> <strong><em>Радиостанция “София”, 6.05.97
            г.</em></strong></p>
            <p><strong><em>А.Копировский:</em></strong> Что же произошло сегодня? Мы все были свидетелями, и все, кто
            стоял в храме, — их было около 300 человек — что второй священник просто служил как бы собственную
            литургию, не признавая той, которую совершал настоятель. Если бы были просто проблемы молодого человека,
            только что закончившего семинарию, если бы он ревностно отстаивал свои взгляды, мы, как всегда, открыты
            диалогу, мы готовы говорить с кем угодно. Но здесь, как мы уже наблюдали в течение недели, было
            последовательное поведение, направленное на то, чтобы внести совершенно иной дух в богослужение и,
            значит, пытаться разделить общину. Была попытка дискредетировать ту литургию, которую совершал
            настоятель, на слова и действия которого все мы говорили “Аминь”. Если бы, повторяю, была просто
            “ревность не по разуму”, это бы как-то проявилось, потому что искренность видна всегда. Но мы с каждым
            днем все более и более убеждались, что здесь речь идет не о таком вот юношеском пыле, а просто о
            стремлении навязать свою волю и, как показалось, даже не свою, а ту, которая была кем-то
            продиктована.<br/> <strong><em>Д.Гасак: </em></strong>Представьте себе ситуацию: предстоятель, который
            является устами общины, совершает Евхаристию, читает анафору и в той части, когда читается
            эпиклесис, происходит преложение Св. Даров, произносит слова: “И сотвори Хлеб сей…” и знаменует
            Св.Тело — второй священник делает то же самое. Предстоятель говорит, что не нужно этого делать —
            таким же образом все происходит и во время благословения Чаши, и на третье прошение. Фактически
            народ, стоящий в храме, видит и слышит, как один священник совершает Евхаристию, благословляет Дары
            — и второй делает то же самое! Что, конечно, вызывает крайнее возмущение в народе, ситуация
            вопиющая, и многие священники, которые об этом услышали, были просто в глубочайшем недоумении. <br/>
            <strong><em>В.Котт: </em></strong>Здесь важно сказать, что, конечно, никогда наша община не
            будет бороться ни против какого человека, потому что Господь нам не заповедал бороться против
            плоти и крови. Господь нам заповедал бороться за человека. И понятно, что наша цель во время
            этого диалога — не заклеймить человека, не втоптать его в грязь (с человеком нельзя обходиться
            как с котенком, тыкая его мордочкой в лужицу). Наша цель — бороться всегда со злом. За человека,
            но со злом. И понятно, что тот молодой батюшка, который был прислан в наш приход, не свою только
            волю исполняет, понятно, что каким-то образом проявляется давление фундаментализма даже на наше
            саященноначалие. Тому примером — последние выпуски “Православие или смерть”, где подвергаются
            хулению не только о. Георгий, наш настоятель, и другие священники, но и практически все члены
            Священного синода во главе с Патриархом. Но так как мы должны, сохраняя уважение к сану молодого
            батюшки, говорить сдержанно, то обратимся к канонам. Прежде всего, несколько дней
            новоназначенный батюшка, присутствуя во время Евхаристии в алтаре, не причащался, что вызывало
            огромное смущение у людей. И в данном случае 8-е апостольское правило говорит совершенно
            однозначные вещи. Это довольно редкий случай, когда и дух, и смысл, и даже буква канона говорят
            одно и то же. Зачитаем это правило: “Аще епископ, или пресвитер, или диакон, или кто либо из
            священного списка, при совершении приношения (Евхаристии) не причастится: да представит причину,
            и аще есть благосклонна, да будет извинен. Аще же не представит: да будет отлучен от общения
            церковного, яко соделавшийся виною вреда народу, и на совершавшего приношение наведший
            подозрение, аки бы неправильно совершал.” Так вот, так как это было не один раз, настоятель
            спрашивал у молодого батюшки причину, причина не была названа (”я скажу духовнику” — был ответ),
            конечно, народ был в страшном недоумении. Но то, что произошло вчера и сегодня (вчера о.
            настоятель все объяснил молодому батюшке, и сегодня все повторилось) — это невозможно.
            Апостольское правило говорит о гораздо меньшем, чем то, что произошло сегодня. Потому что когда
            совершается вторая Евхаристия, когда вторично благословляются Св. Дары на глазах у народа, то
            это, конечно, величайшее смущение и величайший соблазн. Самые мягкие канонические толкования —
            не только Зонара, Аристина, но даже Вальсамона — говорят, что это духовное лицо подвергается
            отлучению.<br/> <strong><em> А.Копировский: </em></strong>Здесь, может быть, самая большая
            проблема в том, что люди, которых мы ответственно и уверенно называем фундаменталистами, как
            впрочем они называются во всем мире, по сути своей — крайние индивидуалисты. Вот чего они не
            признают, так это общины. Для них понятие общины принципиально закрыто. Они могут еще понять
            слово “коллектив”, могут понять слово “толпа” (как и воспринял наш приход новый молодой
            священник), но вот что это община, что это единое тело, живая клеточка церковная, что община не
            должна просто механически повторять заученные возгласы по тексту (а вы знаете, что во время
            богослужения в нашем храме все поют, по-своему, как миряне, сослужа своему предстоятелю или
            предстоятелям)… Было полное разрушение, и, самое главное — игнорирование общины, которой было
            сказано, что вообще никакого дела вам до того, что совершается в алтаре, нет: “это мое дело, я
            так буду делать, я за это сам отвечу”. Конечно, тем самым он навел очень серьезные подозрения на
            пославших его в наш храм, и хотя мы надеемся, что такие открыто преступные действия не могут
            быть инспирированы прямо Святейшим Патриархом, но тем не менее, этот человек вел себя так, как
            будто за ним стоят очень мощные силы.<br/> <strong><em> В.Котт: </em></strong>Как будто он имеет
            санкцию так себя вести.<br/> <strong><em>А.Копировский:</em></strong> Да. Как епископ. Он
            пытался сегодня отлучать от причастия вслух, пытался физической силой прорваться к
            престолу. И здесь, вы понимаете, это уже неблагочиние, о котором приходится говорить,
            потому что сейчас не время молчать. Это не тот случай, когда сор остается в избе. Если
            бы была ревность не по разуму молодого батюшки, мы бы тысячу раз стерпели. Мне пришлось
            по роду своей деятельности (я пять лет преподавал в духовной академии) видеть всяких
            батюшек, и вообще за 26 лет жизни в церкви, я знаю, что всякое можно потерпеть, и все
            действительно могло бы устроиться. Но здесь была сознательная политика на разрушение, и
            сегодня пришлось, что называется, сомкнуть ряды.<br/>
            <strong><em>Д.Гасак:</em></strong> Я расскажу просто, что было дальше. Конечно,
            после того, как совершился эпиклесис, о. Георгий приостановил службу и попросил
            этого священника отойти от престола и разоблачиться, потому что дальнейшее
            сослужение было невозможно. После четырехкратного настоятельного такого прошения
            второй священник остался у престола, сказал “я не уйду” и продолжал служить.
            Естественно, потом — потому что эта ситуация должна была как-то разрешиться,
            невозможно было это терпеть, потому что тут уже встал вопрос, верим ли мы в то, что
            мы делаем, совершая Евхаристию — алтарники окружили престол, и второй священник не
            смог причаститься, хотя очень хотел (он сказал “никто не воспретит мне причаститься”
            после того, что он совершил). После чего, когда уже было причащение мирян, он
            разоблачился, сел в машину, которая его ждала около храма, и уехал.</p>
            <p>***</p>
            <p><em><strong>О. Михаил рассказывал о событиях 6 мая дважды, причем кардинально различным образом.</strong></em>
            </p>
            <p>ИЗ ИНТЕРВЬЮ О. МИХАИЛА ДУБОВИЦКОГО<br/> <strong><em>Радио «Радонеж» 21-22 мая 1997 г. <br/> Интервью было
            опубликовано в одноименной газете <br/> и в «Русском вестнике» за май 1997 г.</em></strong></p>
            <p><strong><em>О. Михаил:</em></strong> 6 мая, в день памяти св.Георгия Победоносца, конфликт приобрел
            особую остроту. Скажу прямо: было совершено церковное преступление! Из истории мы знаем, что такие
            преступления совершали только янычары во времена турецкого ига и во времена большевизма — обновленцы.
            Поэтому я хотел бы с искренностью и правдивостью изложить то, что произошло.<br/> Было это во время
            Евхаристического канона. Каждый священник, служащий литургию, обязан прочитывать про себя молитвы
            Евхаристического канона. Но так как о. Георгий Кочетков совершает литургию по своему чину, я
            старался досконально и строго вычитывать все положенные молитвы, а тайносовершительные слова
            прочитывал полушепотом. Не скажу, чтобы я их прямо вслух произносил, именно полушепотом. Видимо, о.
            Георгия это соблазнило. Возможно, сильно соблазнило, потому что 6 мая, в день Радоницы, после
            преложения Святых Даров, мне было трижды приказано (именно приказано) разоблачиться и не совершать
            далее Божественную Литургию! Естественно, я отказался это делать (также трижды!). На что о. Георгий
            попросил находящихся рядом алтарников «помочь мне разоблачиться». Они уже собрались это сделать, но
            я сказал, что я священник, предстою перед Престолом, и попросил ко мне не прикасаться руками. Тогда
            о. Георгий назвал меня раскольником и, перекрестив три раза, заявил, что во мне сидит бес. Я же
            попросил о. Георгия продолжать служение литургии, потому что перед нами Тело и Кровь Христовы.
            Литургия была продолжена, но после пения «Отче наш», после Запричастного стиха алтарники храма
            окружили престол и не допустили меня ко Святому Причащению, несмотря на мои неоднократные просьбы
            допустить к принятию Святых Таин. <br/> <strong><em> Евгений Никифоров (председатель общества
            «Радонеж»):</em></strong> Возможно ли это?! <br/> <strong><em>О. Михаил:</em></strong> Это
            абсолютно недопустимая вещь, потому что такое решение может принять только Собор Православной
            Церкви. Тут не может быть оправданием различная богослужебная практика.</p>
            <p>ИЗ ВЫСТУПЛЕНИЯ О. МИХАИЛА ДУБОВИЦКОГО<br/> <strong><em>Прямой эфир радиостанции “Радонеж” 10 июня 1997
            г., <br/> после обнародования различными изданиями действительной причины конфликта (выдержки из
            проходившего в прямом эфире выступления опубликованы в виде интервью в газете “Православная Москва”
            в июле 1997 г.).</em></strong></p>
            <p><strong><em>Вопрос:</em></strong> Что конкретно случилось в храме 6 мая?<br/><strong><em>О.
            Михаил:</em></strong> В печатном издании братства “Сретенский листок” (май, N 7) происшедшее описано
            следующим образом: “На Божественной литургии 6 мая при чтении тайносовершительных молитв (эпиклесиса) он
            (<strong><em>о. Михаил. — Ред.</em></strong>) снова благословил Св. Хлеб, осенив его крестным знамением
            вслед за предстоятелем. После замечания о. Георгия и просьбы больше не повторять этого о. Михаил
            повторно благословил и Св. Чашу. Несмотря на новое замечание, о. Михаил сделал это и в третий раз после
            слов “Преложив Духом Твоим Святым”. Все описано верно. При этом я еще вслух произносил тропарь третьего
            часа и тайносовершительные слова. Однако нигде нет канонического запрещения совершать такие действия.
            Наоборот, в “Учительном известии”, помещенном в конце Служебника, ясно сказано: “Подобает убо, аще
            служат иерее мнози, якоже словеса Господня: “Приимите, ядите…” и “Пийте от Нея вси …” и “Твоя от
            Твоих…”, “И сотвори убо Хлеб сей…”, “А еже в Чаше сей…”, “Преложив Духом Твоим Святым…”, купно
            глаголати, а не предворяти комуждо глаголати особь”. Есть только практика благословения Святых Даров
            одним предстоятелем собора священнослужителей.<br/> Далее о. Георгий, страшно возмущенный моими
            действиями, в гневе трижды приказал мне разоблачиться. На это я трижды ответил отказом…</p>
            <p className="has-text-align-center">***</p>
            <p><strong><em>После случившегося прихожане просили викария Московской епархии архиеп. Арсения и
            благочинного Сретенского округа прот. Олега Клемышева помочь в разрешении конфликта. “Помощь” состояла в
            распоряжении архиеп. Арсения: вместо о. Георгия служить литургию по субботам будет о. Михаил. Несмотря
            на все просьбы прихожан, архиеп. Арсений и прот. Олег Клемышев настояли именно на таком решении.<br/> На
            службы в Успенском храме по призыву радиостанции “Радонеж” постоянно приходило 30-40 человек из
            Сретенского монастыря, Афонского подворья и из некоторых других приходов. Они пытались петь вместо
            хора, перебивать проповеди о. Георгия, перекрикивать совместное пение прихожан. После каждой службы
            стихийно возникали разговоры о сложившейся ситуации между постоянными прихожанами и “гостями”
            прихода. 8 июня прошла “открытая встреча”, куда для обсуждения ситуации в храме Успения в Печатниках
            были приглашены все желающие прихожане разных московских храмов. Разговор состоялся, хотя и было
            много резких и просто провокационных вопросов. <br/> Со временем “группа поддержки о. Михаила” редела
            и к концу июня на богослужения приходило лишь несколько “гостей”.</em></strong></p>
            <p><strong>СВИДЕТЕЛЬСТВА ПРИХОЖАН</strong></p>
            <p>Обращаюсь к вам, потому что вас, “группу поддержки” из 32 человек видела у нас в храме, когда вы, вне
            расписания пришли к нам и служили утреню и потом были на всей службе дальше.<br/>
            Я спросила: “Скажите, вот так я тоже смогу прийти в любой храм и начать петь у вас вместо хора во
            время службы?” — Можете (???) Это что, издевка? Перед моей сединой. За что?<br/>
            А.Д.Василевская</p>
            <p>В наш храм был назначен помощником и. о. настоятеля свящ. Георгия Кочеткова иерей Михаил Дубовицкий. Мы с
            любовью приняли молодого батюшку, но он с первых же дней, называя нас “паствой свой любимой“, отказался
            нас причащать, разговаривать с нами после службы. При этом после призывов о помощи, прозвучавших по
            радио “Радонеж“, к нам в храм стали приходить какие-то люди, называвшие себя православными христианами,
            но которые вели себя в храме неблагоговейно, нас называли еретиками, а после служб устраивали с о.
            Михаилом сходки в храме, на которых открыто обсуждали, как надо нас, постоянных прихожан, выгонять из
            нашего храма. О. Георгий постоянно в проповедях, на исповедях, в беседах призывал нас к терпению,
            смирению, кротости, несмотря на бесчиние, происходящее в храме.<br/>
            С.Волкова</p>
            <p>Мы так были рады, узнав, что у о. Георгия появился помощник. У нас большой приход, большая часть, как и
            я, причащаются каждую неделю, о. Георгий загружен до предела. Поражаешься иногда, откуда у него столько
            сил. И вот пришел о. Михаил. Сразу начались проблемы. Стоило огромного напряжения бывать на службах.
            Откуда-то приходили люди в “поддержку” о. Михаила, они позволяли себе выкрики на литургии,
            поворачивались спиной к о. Георгию и в ответ на его проповеди или обращения кричали, что все ложь и у
            нас не Церковь Христова. С каким же терпением и любовью о. Георгий старался их успокоить, такой выдержки
            я ни у кого не видела. Поэтому все, что сейчас говорится и пишется о нас, о нашем приходе, я со
            спокойной совестью могу назвать клеветой.<br/>
            Е.Л.Малышева.</p>
            <p>Выдержать две службы подряд с о. Михаилом (утреню и литургию) у меня не было сил. Его демонстративное
            непослушание настоятелю храма о. Георгию, открытое непризнание его как священника (о. Михаил несколько
            раз переосвящал Святые Дары в алтаре), постоянные попытки изменить чин богослужения во время
            богослужения, присутствие в храме каких-то странных личностей после передач радиостанции “Радонеж“,
            якобы пришедших “поддержать молодого священника“, но на самом деле пришедших просто поучаствовать в
            назревавшем конфликте, и просто в качестве зевак — все это крайне обострило обстановку во время
            богослужений, и очень многие наши прихожане стали ходить в другие храмы.<br/>
            Мы знали, что всем своим поведением о. Михаил провоцирует нас пойти на конфликт с ним, и терпели все
            его выходки, оскорбления, которые сыпались на нас со стороны людей, пришедших “поддержать” о.
            Михаила, да и сам о. Михаил стал открыто называть нас сектантами. Это была совершенно открытая
            травля о. Георгия и нас, его прихожан.<br/>
            Т.И.Абрамова.</p>
            <p>Я была свидетелем того, что о. Михаил Дубовицкий, начавший служить в нашем храме в апреле сего года, с
            самого своего появления в храме проявлял непослушание о. настоятелю, вплоть до того, что смеялся в ответ
            на его замечания, пытался прерывать проповеди, повторно благословлял Св. Дары. Поведение о. Михаила с
            самого начала было демонстративным, он явно играл на публику, производя впечатление человека психически
            неуравновешенного: уходил в ризницу во время проповедей о. Георгия, пытался провоцировать людей,
            приходящих для “молитвенной помощи“ ему по призыву радиостанции “Радонеж“, на неадекватные действия,
            крики прямо перед причастием, в своих проповедях и выступлениях с амвона настраивал их на поиск врагов,
            явно намекая на нашу общину и ее предстоятеля. В таких условиях участвовать в богослужениях и молиться
            “единым сердцем и едиными устами“ было весьма трудно. Совершая все эти действия, о. Михаил ссылался на
            имя Святейшего, благословившего его на служение в этом храме. Несмотря на заверения о своем горячем
            желании иметь общение с паствой, о. Михаил отказывался от каких-либо встреч с группами прихожан,
            предлагая приходить к нему на беседы поодиночке, по записи и по вопросам, не касающимся Евхаристической
            практики, к которой миряне якобы не имеют никакого отношения. Странными казались и заявления о. Михаила
            по поводу церковного богослужения вообще: настаивая на своем желании служить самостоятельно, он говорил,
            что не нуждается при этом в народе, отказывался причащать людей, не исповедовавшихся в этот день у него,
            требовал недельного поста перед причастием.<br/>
            О. благочинный, приезжавший в храм, не стал даже разговаривать с прихожанами, хотя мы рассчитывали
            на его помощь в сложившейся ситуации.<br/>
            О.Р.Астапова</p>
            <p>Ввиду отсутствия из-за болезни о. Георгия в день Победы, утреню и заупокойную литию служил о. Михаил.
            После службы прихожане попросили о. Михаила ответить на их вопросы, в частности, признает ли он нашу
            Евхаристию и почему действует как сектант, дублируя действия о. Георгия, несмотря на его запрещение
            мешать службе. О. Михаил ответил, что он не обязан отвечать на наши вопросы и никакого повторного
            благословения Святых Даров он не производил. Видимо, о. Михаил забывает, что и как он делает. Несколько
            десятков прихожан своими ушами слышали пререкания молодого священника с нашим предстоятелем во время
            анафоры, это является сильным искушением для нас. По отношению к нам он ведет себя то фальшиво улыбчиво,
            то проявляя полное неуважение и пренебрежение.<br/>
            Поведение о. Михаила нельзя объяснить иначе, как наличием высоких и влиятельных покровителей в
            Московской патриархии, которые пользуются Вашим доверием. Они направляли о. Михаила на
            провоцирование открытого возмущения прихожан нашего храма. О. Михаил ведь заявил нам о том, что если
            приход выступает против священника, то всех прихожан отлучают от Церкви.<br/>
            Смирение и пример о. Георгия и его выдержанные и мудрые проповеди предотвращали беспорядки и
            возмущение общины. Епископ Арсений ни разу к нам не приехал, а когда наши прихожане ходили на прием
            к нему, он их не слушал, а требовал, чтобы к нему явился о. Георгий, хотя наш настоятель был болен и
            находился в больнице.<br/>
            Зная о тяжелом положении в нашем приходе, вызванном поведением о. Михаила, владыка Арсений ни разу к
            нам не пришел, а ведь он наш викарный епископ. А больной батюшка приходил к нам из больницы на
            богослужения, так как он видит в служении Богу весь смысл своей жизни, а в трудную пору хотел
            поддержать и успокоить свою паству. Мне приходилось слышать обвинения, что о. Георгий для нас
            важнее, чем Господь Бог. Почему нас оскорбляют недоверием к нашей вере в Бога? Мы любим нашего
            духовного отца, благодарны ему именно за то, что он привел нас к Богу и в Церковь, учит нас любить
            Бога, слово Божие и Его заповеди. Но путать отношение к Богу с отношением к человеку может только
            тот, кто не понимает разницу между Творцом и тварью или хочет сознательно оскорбить.<br/>
            Наш батюшка, больной, тревожась за нас, служил литургию, проповедовал, ободрял нас, призывая быть
            настоящими христианами, церковными людьми, молиться за о. Михаила. И мы молились. Сначала нам было
            трудно молиться за него, но потом стало его жалко — он выглядел несчастным человеком.<br/>
            О. Георгий не раз призывал о. Михаила покаяться в бесчинном поведении на Евхаристии, оскорбившем
            чувства верующих, но о. Михаил не чувствовал раскаяния и не хотел мира и добрых отношений с общиной.<br/>
            М.Силонова (Из письма патриарху)</p>
            <p>Обвинения против свящ. Георгия Кочеткова строятся на основе бесцеремонного манипулирования цитатами из
            его работ и откровенных небылиц типа: “у них не причащают младенцев“, “у них служат на современном
            русском языке”, “у них миряне потребляют Святые Дары.” В результате создается образ врага внутри нашей
            церкви, которым уже введены в соблазн многие тысячи православных христиан и даже священноначалие. Один
            несчастный человек, пришедший для поддержки свящ. Михаила Дубовицкого по призыву “Радонежа“, искренне
            заявил: “за врагов церкви (т. е. прихожан нашего храма) не молятся, их уничтожают.”<br/>
            Н.Голубин</p>
            <p className="has-text-align-center">***</p>
            <p><strong><em>В течение мая—июня 1997 г. конфликт в приходе Успения в Печатниках освещался различными
            средствами массовой информации. Приводим несколько публикаций. (Основная часть интервью о. Михаила
            Дубовицкого, опубликованного в майских номерах газет “Радонеж” и “Русский вестник” приведена выше).</em></strong>
            </p>
            <p>КОНФЛИКТ В ПРИХОДЕ <br/>
            СВЯЩЕННИКА ГЕОРГИЯ КОЧЕТКОВА <br/>
            “НГ-Религии”. 29.05.97 г.</p>
            <p>В московский храм Успения в Печатниках, принадлежащий одному из самых больших приходов Москвы, которым
            руководит священник-реформатор Георгий Кочетков, назначен вторым священником молодой выпускник Курской
            духовной семинарии Михаил Дубовицкий. Между настоятелем храма о. Георгием Кочетковым и вновь назначенным
            иереем сложилась непростая ситуация вследствие различного подхода двух батюшек к некоторым обрядовым
            элементам богослужения. Прихожане храма, в свою очередь, выражают недовольство тем обстоятельством, что
            Московская патриархия отказывается рукополагать в священники для нужд общины подготовленных в храме
            Успения мирян. </p>
            <p>А.Колымагина, Б.Колымагин<br/>
            ОБЫКНОВЕННЫЙ ФУНДАМЕНТАЛИЗМ <br/>
            “Коммерсант-daily” № 73. 21 мая 1997 г.</p>
            <p>Сегодня информация некоторых источников о жизни православной церкви может смутить кого угодно. И
            верующий, и неверующий будут равно озадачены, услышав, скажем, по «православной» радиостанции «Радонеж»,
            что «русская культура разрушалась не только последние 70, но и все последние триста лет» (видимо,
            Ломоносовым, Пушкиным и Достоевским). Не менее удивительным покажется утверждение «православной»
            телепередачи «Русский дом» о том, что папа римский ждет не дождется прихода антихриста. «Православие или
            смерть!», «Да здравствует Христос — наш русский Бог!» взывают к читателю распространяемые на папертях
            или, скажем, в клыковском Фонде славянской письменности и культуры издания. Из них можно узнать, что к
            врагам православия относятся не только пресловутые мифические жидомасоны и католики, но и практически
            все члены нынешнего Священного синода Русской православной церкви, а также православные патриархи —
            вселенский Варфоломей и русский Алексий II. <br/>
            Набирающий силу церковный фундаментализм выступает под лозунгом «держать предания отцов и старцев».
            Современные христиане, по мнению его сторонников, обязаны хранить и блюсти обрядовые формы XIX века,
            как гарантию сохранения чистоты веры. Все чаще приходится встречаться с «православными», рядом с
            которыми — темно и жить не хочется, которые Евангелие подменяют специфически понятым церковным
            Типиконом. Все чаще берешь в руки «православные» издания с опасением: кого разоблачат или отвергнут
            сегодня? <br/>
            Идеология фундаментализма не имеет ничего общего с богословскими глубинами той веры, на которой он
            паразитирует; она примитивна, как выеденное яйцо: кто не с нами, тот против нас, если враг не
            сдается… и т. д. Враги же, как известно, делятся на внешних и внутренних. Ксенофобия, болезненная
            закрытость ко всем чужим, сектантское объявление экуменизма «ересью XX века» и явным признаком
            всеобщей апостасии (отступничества) сопровождаются провокациями и преследованиями по отношению ко
            всему сколько-нибудь живому и свободному внутри православной церкви. В ряду антицерковных действий
            фундаментализма наиболее известны такие факты, как запрещение в священнослужении одного из немногих
            серьезных последователей древневизантийской и древнерусской духовной традиции, всемирно известного
            иконописца архимандрита Зинона (Теодора). Достаточно прочитать доклад синодальной богословской
            комиссии на недавно прошедшем Архиерейском соборе об экуменической деятельности Русской православной
            церкви, чтобы убедиться в явной предвзятости запрета, не говоря уже о полной канонической
            несостоятельности этого документа. Блокирована дерзнувшая перейти на русский язык в богослужении и
            адаптировать его для детей и молодежи самая большая в Херсоне община Сретенского храма, все пять
            клириков которой недавним указом Управляющего Херсоно-Таврической епархией архиепископа Илариона
            извержены из сана. Под сильным давлением православной прессы находится даже митрополит
            Санкт-Петербургский и Ладожский Владимир, в самом мирном тоне высказавшийся в одной из своих
            рождественских проповедей в защиту нового календарного стиля в церкви. На последнем ежегодном
            епархиальном собрании духовенства Москвы подверглись атаке авторы программ радиостанции «София»
            свящ.Георгий Чистяков и свящ. Владимир Лапшин. Протоиерей Николай Кречетов потребовал, как на
            партсобрании, путем голосования (то есть нарушая те самые каноны, о которых так много шумят) лишить
            их священного сана. Ожидаемый лес рук, правда, слава Богу, не поднялся, да и Святейший Патриарх
            Алексий II напомнил досточтимому батюшке, что пока еще патриарх он, а не о. Николай. К этому же ряду
            можно отнести и вновь набирающее силу преследование Сретенского братства и поддерживаемого им
            прихода Успения Пресвятой Богородицы в Печатниках. <br/>
            Напомним, что состоявшийся три года назад насильственный роспуск приходской общины Владимирского
            собора нынешнего Сретенского монастыря (поддерживаемой тем же братством) сопровождался выдержанными
            в духе «духовного большевизма» («Единство Церкви») тенденциозными конференциями и дезинформацией
            общественности со стороны тех же церковных СМИ: «Русский Вестник», «Радонеж», «Русь державная» и
            проч. За три года творцы гонения выпустили несколько сборников, название которых говорит само за
            себя: «Сети обновленного православия» и «Современное обновленчество — протестантизм “восточного
            обряда”», серия изданий «Православие или смерть». <br/>
            Вся эта деятельность, правда, не достигла своей цели: община Успенского храма под духовным
            руководством ректора Свято-Филаретовской высшей православно-христианской школы свящ.Георгия
            Кочеткова продолжала расти и насчитывает сегодня более двух тысяч человек активных членов — в
            основном это интеллигенция и молодежь. А ведь относительно недавно здесь еще размещался музей
            Морского флота: макеты судов соседствовали с работами художников-маринистов, и чучело альбатроса с
            бодлеровской беспомощностью встречало посетителей. Музей переехал, и в храме — этом корабле в
            житейском море — возродилась в полноте общинно-приходская жизнь. <br/>
            Кроме уставных богослужений здесь проводится катехизация — годовая подготовка взрослых к крещению
            или к первой сознательной серьезной исповеди и причастию, причем каждый год ее проходит около тысячи
            (sic!) человек. Прихожанами, членами братства практикуются постоянные встречи для совместного
            изучения Священного Писания и Предания. Они не чужие друг другу: крещение детей, венчание, отпевание
            — вся жизнь человека проходит при живом участии общины. Многие известные православные богословы и
            церковные деятели, такие как прот. Иоанн Мейендорф, митрополит Сурожский Антоний, архиепископ Михаил
            (Мудьюгин), академик С.С.Аверинцев и др., видели и видят в практике прихода Успения в Печатниках
            всестороннее и полнокровное возрождение православной церковной жизни в современной России. Один из
            идеологов крайнего церковного фундаментализма, нынешний игумен Сретенского монастыря Тихон
            (Шевкунов), рассуждая о деятельности о. Георгия Кочеткова, оценил ситуацию таким образом: «Все эти
            три года дискуссии продолжаются, а между тем эти силы неуклонно усиливаются». Для того, чтобы
            разрушить крепкую церковную общину, ее противники прибегли к новой тактике. О. Георгию назначили
            «помощника» — молодого священника того же фундаменталистского направления, разумеется, под влиянием
            его власть имеющих единомышленников. Первое, что сделал о. Михаил Дубовицкий, появившись на приходе
            Успения, так это «выразил свое неудовольствие» настоятелю. «Неудовольствие» 28-летнего выпускника
            Курской семинарии, самоуверенного, но невежественного, вызвала служба в храме. Евангелие читалось
            по-русски, непонятные славянские слова в молитвах были русифицированы, прихожане пели молитвы хором
            и хором же говорили «Аминь». Всю эту практику, уже когда-то одобренную Поместным собором 1917-1918
            гг. и благословленную в свое время на одном из приходов патриархом Сергием (в бытность его
            московским митрополитом), отечественные фундаменталисты, вслед за карловацкими раскольниками,
            считают ересью. О. Михаил в полном соответствии со своей фундаменталистской верой начал делать
            страшные и кощунственные вещи: стал внушать прихожанам, что их настоятель — как бы и не священник.
            Что исповедь его — вовсе не исповедь. И причастие — как бы и не причастие… Все это говорилось и
            демонстрировалось тем самым людям, которых о. Георгий привел в церковь. Естественно, это вызвало
            возмущение, но прихожане, тем не менее, не поддались на провокацию скандала. Они прекрасно понимают,
            что зараза фундаментализма поразила не только многих священников, но и часть епископата. У начавшего
            конфликт «помощника» найдется немало влиятельных покровителей — сегодняшних недоброжелателей о.
            Георгия. Только дай предлог — и не новоназначенного святотатца, успевшего за неделю нарушить столько
            церковных канонов, а настоятеля храма отправят за штат или переведут куда-нибудь подальше. Уже
            сейчас усердно муссируются слухи, что якобы о. Михаилу не дают служить и проповедовать и даже что
            его насильно вывели из алтаря во время богослужения (распространители слухов, видимо, не знают, что
            весь эпизод кощунственного поведения второго священника, как и его уход из алтаря, оказался заснят
            на видеопленку, ибо это происходило в день ангела о. Георгия). <br/>
            Еще одна распространяемая фундаменталистскими источниками «информация» — о якобы имевщем место
            непослушании церковной иерархии со стороны прихода и настоятеля. Судя по всему, добиться этого —
            цель всей интриги. Если она осуществится, — Москва лишится своей самой живой и просвещенной общины.
            На очереди же будут следующие. Опыт тоталитарного прошлого подсказывает: такая очередь может быть
            продолжена до бесконечности. Вспомним двадцатые годы. Сначала уничтожили самых ярких, потом тех, кто
            боролся с ними, а потом наступил застой, серость и мрак. Это касалось и церковной жизни, причем
            унификация достигалась не только репрессиями. Так, например, в августе 1945 года Совет народных
            комиссаров принял решение о введении во всех действующих монастырях (чьи уставы и богослужения
            несколько отличались один от другого) единого устава. После упразднения Совета по делам религии и
            его уполномоченных заботу о жестком единообразии для воспитания слепой и фанатичной (или наоборот —
            секуляризованной) веры приняли на себя отцы-фундаменталисты. Насколько далеко они зайдут в своем
            наступлении, покажет будущее. Справедливости ради, заметим, что это беда не одного православия.
            Похожие тенденции мы видим и в других религиях. Не случайно даже в сравнительно демократичном
            Стамбуле снова поговаривают о переделке Св.Софии из музея в мечеть. Постепенно из явления
            религиозного фундаментализм становится явлением общественным. Вполне возможно, что нашим потомкам
            придется жить в мире тоталитарных теократий, жестко контролирующих не только общественную, но и всю
            личную жизнь своих граждан.</p>
            <p>РАДИОПЕРЕДАЧА “В ГОСТЯХ У “ЭХА МОСКВЫ” </p>
            <p><strong><em>22 августа 1997 г. на волнах радиостанции “Эхо Москвы” прошла передача “В гостях у “Эха
            Москвы” (ведущий — Сергей Бунтман). Частично она была посвящена оценке ситуации, сложившейся в приходе
            Успения в Печатниках в мае—июне того же года.</em></strong></p>
            <p><em><strong>С.Бунтман:</strong></em> Это было не случайно, что Михаил Дубовицкий был назначен именно в
            Ваш храм?<br/><em><strong>О. Георгий: </strong></em>Да, я думаю, что это было не случайно. Формально это
            выглядело так: назначение священника в помощь мне; реально это было наоборот. Хотя мы просили
            священника для помощи, потому что одному мне было очень трудно справляться с приходом в несколько
            тысяч постоянных членов. Но мы просили, естественно, человека, который хотя бы уважал наши традиции,
            если уж не прямо из нашего прихода. Но вот этого, к сожалению, не получилось. И привело это к тому,
            что на Пасху и после Пасхи стали возникать постоянные конфликты.<br/> <em><strong>С.Бунтман:</strong></em> О.
            Георгий, простите, я хотел вот что спросить. То, что произошло этим летом, это, видимо, итог
            длительных разногласий и конфликтов. Первый вопрос чисто формальный, чтобы понять о чем идет
            речь. Вы подчиняетесь Московской патриархии?<br/> <strong><em>О. Георгий:</em></strong> Конечно.
            Мы члены Русской православной церкви, и для нас это очень важно.<br/>
            <em><strong>С.Бунтман:</strong></em> В чем тогда суть разногласий?<br/> <strong><em>О.
            Георгий:</em></strong> Мне кажется, что хотя разногласия в церкви естественны, они
            должны быть в едином духе, они должны быть прежде всего за Церковь, за те духовные
            ценности и реалии, которыми Церковь живет. Но мы в этом конфликте столкнулись с силами
            не столько консервативными в церкви, не столько традиционными — мы сами высоко ценим эти
            ценности, — сколько с силами фундаменталистскими или интегристскими, или
            изоляционистскими (их по-разному называют), которые по сути не являются даже ни
            христианскими, ни очень церковными. И поэтому, от того, как пойдет это дело и каковы
            будут его последствия, зависит очень много не только в судьбе нашей или в судьбе церкви,
            но и в судьбе всего народа и общества. Я в этом уверен.<br/>
            <em><strong>С.Бунтман:</strong></em> Но в чем же сам конфликт? В чем он состоит?
            Пускай это, действительно, силы, которые можно назвать не консервативными, а
            фундаменталистскими.<br/> <strong><em>О. Георгий:</em></strong> Знаете, вот очень
            интересно, что, когда начинаешь разбираться по существу, то почти ничего не
            остается. Рациональная логика здесь почти не работает. Просто слишком многие в
            церкви привыкли жить ценностями еще ветхозаветными (это в лучшем случае
            ветхозаветными), которые предполагают священную букву, а апостол Павел не случайно
            говорит, что всякая “буква убивает, а Дух животворит”. Для нас ценность Свободы и
            поэтому ценность Духа всегда была превыше всего.<br/> <em><strong>С.Бунтман:</strong></em> О.
            Георгий, да, я понимаю. Но Вы не считаете, что подобного рода вещи, которые, как
            мы знаем из истории Православия, приводили к достаточно сложным конфликтам
            (можно вспомнить хрестоматийный раскол), требуется решать
            соборно?<br/><strong><em>О. Георгий:</em></strong> Вы знаете, как раз именно
            раскол говорит вот о чем: там ведь вся проблема была в том, что реформа
            спускалась сверху вниз, без инициативы снизу, без подготовки церковного
            народа, без его желания. У нас совершенно другой случай. Как раз община,
            церковный народ снизу единогласно пришли к тем выводам, которые мы
            используем как основание для на самом деле традиционных, но все же изменений
            в практике приходской жизни. Мы как раз надеемся на то, что наш пример
            позволит иерархии и всей церкви увидеть проблемы, внутренние проблемы и
            начать их обсуждать. Это наша цель.<br/>
            <em><strong>С.Бунтман:</strong></em> И еще один вопрос. Не секрет, что в
            Православии, особенно учитывая огромное количество неофитов, которое
            появилось в последнее время, одна из категорий, которая постоянно
            выдвигается на первый план — это категория послушания, насколько я
            понимаю. А что Вы думаете, что Вы можете ответить по этому поводу,
            учитывая, что Православная церковь, как иерархический организм, отнюдь
            не демократична, т.е. к демократии на самом деле не имеет никакого
            отношения. Что Вы можете сказать по этому
            поводу?<br/><strong><em>С.С.Аверинцев:</em></strong> Маленькая реплика по
            вопросу о послушании. Ни в одной из статей, в которых восхваляется как
            великий подвиг поведение младшего священника Михаила Дубовицкого не
            упоминается такая мелочь. Все два месяца, что в храме было два
            священника, о. Георгий Кочетков сохранял обязанности настоятеля. Даже
            если у священника, младшего по возрасту, по опыту и по положению есть
            убеждения, расходящиеся с убеждениями старшего, — это серьезное дело. Но
            упраздняет ли это именно с консервативной точки зрения обязанность
            миролюбивого послушания? Обратите внимание: никто из сторонников о.
            Михаила даже не говорит в этой связи, скажем, о драматичной коллизии,
            столкновении обязанностей. Хотя бы, скажем, одобряя его выбор. Никакого
            выбора, собственно говоря, и не было. Так что вопрос о послушании — это
            вопрос.<br/><br/><br/></p>
            <p><strong>РАДИОСТАНЦИЯ “РАДОНЕЖ” <br/> О КОНФЛИКТЕ В ПРИХОДЕ УСПЕНИЯ <br/> ПРЕСВЯТОЙ БОГОРОДИЦЫ В ПЕЧАТНИКАХ</strong>
            </p>
            <p><strong><em>В освещении радиостанцией “Радонеж” конфликта в приходе Успения Пресвятой Богородицы в
            Печатниках можно выявить следующие закономерности:<br/> 1 этап — от Страстной Среды 1997 г. до 29 июня
            того же года: в Великую Среду в приход назначен молодой священник о. Михаил Дубовицкий. Он
            “высказывает свое недовольство” настоятелю, выбегает на амвон и спорит с ним во время проповедей,
            отказывается от разговоров с прихожанами, неоднократно отказывается причащаться во время служения
            литургии, демонстративно разоблачается и уходит со службы прямо перед причастием, не причащает
            исповедовавшихся у о. настоятеля прихожан, если же причащает, то молча. Наконец, о. Михаил
            неоднократно “переосвящает” Св.Дары на литургии. 6 мая после очередного “переосвящения” о.
            настоятель запрещает ему продолжать службу, (он же пытается этого не исполнить).<br/> Реакция
            “Радонежа”: 13, 16 мая — первые сообщения о том, что у прихожан “не хватило любви” к о. Михаилу.
            Указывается адрес храма.<br/> 21 мая в Москву из очередной поездки приезжает Патриарх. В течение
            недели, начиная с 20 числа, “Радонеж” касается ситуации в Успенском храме (в эти дни
            нисколько не обострившейся по сравнению с предыдущим периодом) каждый день. <br/> При
            этом:<br/> — 21-22 мая транслируется получасовое интервью председателя общества “Радонеж”
            Евгения Никифорова с о. Михаилом Дубовицким, где, в частности, излагается история с
            запрещением продолжать службу, но не указывается истинная причина и все происшедшее
            называется ”страшным церковным преступлением” со стороны прихода и его
            настоятеля;<br/> — в течение остальных дней каждый гость прямого эфира отвечал на
            вопрос ведущего об отношении к о. Георгию и происходящим событиям и говорил о
            том, какого наказания заслуживает, по их мнению, о. Георгий;<br/> — 30 мая
            транслируется слегка модернизированное (опять получасовое) интервью о.
            Михаила Е.Никифорову. <br/> Каждую пятницу, накануне богослужебных дней,
            “Радонеж” приглашает своих слушателей на богослужения в Успенский храм
            “поддержать о. Михаила в этом модернистском храме”.<br/> Общий объем
            вещания “против Успения в Печатниках” за эти десять дней составляет
            около 2 часов, т.е. примерно 10% всего эфирного времени
            “Радонежа”.<br/> За это время в адрес о. Георгия раздаются следующие
            обвинения: “многочисленные не санкционированные церковными
            властями новации” (прот. Владислав Цыпин, 20.05.97 г.), “переход
            на русский язык богослужения в пику не только всем московским
            приходам, но и всему славянскому миру” (прот. Владимир Силовьев,
            23.05.97 г.), “внесение раздора по отношению к полноте церкви,
            полноте церковного учения” (М.М. Дунаев, 29.05.97 г.),
            “невероятное превышение власти” (о. Михаил Дубовицкий). Многие
            из этих обвинений приводятся с одной и той же оговоркой: “как я
            слышал” или “я сам никогда там не бывал”.<br/> Далее следует
            недельный перерыв.<br/> 6 июня ”проблеме” Успения в
            Печатниках посвящен практически весь час прямого эфира.
            Прот. Валентин Асмус, рассказав о выходе Грузии из ВСЦ
            (происшедшем при прямом шантаже патриарха Илии II со
            стороны раскольнических националистических сил) косвенно
            призывает последовать примеру грузинских раскольников и
            в связи с проблемой экуменизма, и в связи с проблемой
            ”неообновленчества”. Предпринята первая попытка
            канонически оправдать поведение о. Михаила во время
            “переосвящения” Св. Даров (хотя сам факт “переосвящения”
            еще не признается). Эта же передача транслируется через
            два дня, 9 июня (после возвращения патриарха Алексия II
            из поездки в Калмыкию). <br/> 10 июня, за день до
            длительной поездки патриарха в Святую землю и в Грац
            (возвращение ожидается лишь в начале июля) весь час
            прямого эфира опять посвящен общине Успения в
            Печатниках, в эфире — о. Михаил и прот. Валентин
            Асмус. Факт “переосвящения” Св. Даров признается
            (впервые за все время) и оправдывается. Практически
            весь час идет осуждение и обсуждение о. Георгия
            Кочеткова вне связи с конфликтом.<br/> Таким образом,
            за пять дней возобновившейся кампании объем
            вещания “против Успения в Печатниках” составляет
            около 3 часов, т.е. примерно 30% всего эфирного
            времени “Радонежа”.<br/> После отъезда патриарха
            Алексия II в течение 18 дней проблема
            затрагивается один раз прямо (озвучено
            краткое приглашение на службу) и один раз —
            косвенно: 27 июня о. Максим Козлов кратко
            призывает патриарха проявить мужество и, не
            боясь раскола, разделаться со всеми
            экуменистами, “неообновленцами” и
            модернистами. Таким образом, объем вещания
            приближается к 0%.<br/> На этом первый этап,
            достаточно жестко связанный с
            присутствием патриарха в Москве,
            заканчивается. За день до возвращения
            патриарха из Граца, 29 июня, происходит
            инцидент в храме Успения.</em></strong>
            </p>
            <p>ИЗ ВЫСТУПЛЕНИЯ ПРОТ. ВАЛЕНТИНА АСМУСА <br/>
            НА РАДИОСТАНЦИИ “РАДОНЕЖ” 6 ИЮНЯ 1997 г.</p>
            <p><strong><em>Прот. Валентин Асмус: </em></strong>Сегодня в первую очередь я хотел сообщить вам о
            чрезвычайно важном событии, которое произошло в Грузинской православной церкви. <br/> 27 апреля, на
            Пасху, настоятель Шимвинского монастыря архим. Георгий объявил в письме на имя предстоятеля
            Грузинской церкви о том, что его монастырь <strong>прекращает евхаристическое общение с католикосом
            патриархом Илией II из-за экуменизма.</strong> Через 3 дня <strong>такое же
            решение</strong> приняла братия Лавры преподобного Давида Гориджийского. Затем к этому
            присоединилась братия Бетанского монастыря, затем последовали еще несколько монастырей по этому же
            пути. Состоялись собрания духовенства, где и приходское духовенство призывало выйти из
            экуменического движения. Как можно себе представить, <strong>голос Грузинской церкви против
            экуменизма был дружный,</strong> и в этой ситуации <strong>епископат принял именно то решение,
            которое ожидалось</strong> церковным народом. Однако же этому решению предшествовал конфликт,
            потому что требование Грузинской церкви выйти из экуменических организаций было высказано в
            чрезвычайно резкой форме. И поэтому священноначалие Грузинской церкви, выходя из экуменического
            движения по требованию церковного народа, сочло в свое время нужным вынести прещение против 4-х
            самых активных противников экуменизма, которые заявляли о том, что они прерывают евхаристическое
            общение со Святейшим католикосом патриархом Илией. Однако же эти 4 настоятеля монастырей на
            следующий же день после синодального постановления припали к стопам Святейшего патриарха с просьбой
            о прощении и они немедленно были освобождены от прещения и все они остались на своих местах
            настоятельских. Это — важнейшее событие, беспрецедентное в жизни современного православия. Шаг
            Грузинской церкви, ее благая инициатива существенно меняет общую ситуацию во вселенском православии,
            она должна дать мужества, дать силы и другим сторонникам православного отношения к проблемам
            экуменизма. <br/> Другая тема, которую я хотел сегодня затронуть, это тема наша московская. Я имею в
            виду ситуацию в приходе Успения Пресвятой Богородицы в Печатниках, где настоятелем — свящ.
            Георгий Кочетков. Недавно в этот храм был назначен в соответствии с неоднократными просьбами
            настоятеля, свящ. Георгия Кочеткова, второй священник, о. Михаил Дубовицкий. Ему пытаются
            создать невыносимую обстановку, поскольку убедились, что это человек железной твердости, это
            человек, которого невозможно будет перевоспитать в кочетковском
            духе.<br/><strong><em>Вопрос: </em></strong>Но ведь у о. Георгия в руках, по-моему, страшное
            оружие; ведь его приход специализируется на миссионерской деятельности. Бог с ним, он в
            ответе перед Богом. Ведь его ученики и последователи пойдут дальше. Как к этому вообще
            относиться? Я не говорю только насчет отца Георгия…<br/><strong><em>О.
            Валентин:</em></strong> Есть много признаков того, что наше священноначалие серьезно
            обеспокоено ситуацией и не собирается терпеть эти явные беззакония, явное попрание
            церковных правил, богослужебного устава, но предоставим священноначалию принимать
            решение. Что же касается нас самих, то будем тверды в православии, потому что <strong>если
            мы будем дружно противостоять</strong> этим новшествам обновленческим, то
            священноначалию будет гораздо легче принимать <strong>те решения, которых мы от него
            ожидаем.</strong></p>
            <p>ИЗ ВЫСТУПЛЕНИЯ О. МИХАИЛА ДУБОВИЦКОГО <br/>
            НА РАДИОСТАНЦИИ “РАДОНЕЖ” 10 ИЮНЯ 1997 г.<br/>
            Здесь печатается по обработанному тексту выступления, опубликованному в виде интервью в газете
            “Православная Москва” <br/>
            в июле 1997 г.</p>
            <p><strong><em>О. Михаил:</em></strong> Конечно, сейчас очень сложная ситуация, но именно в экстремальных
            условиях отделяется черное от белого и ложь от истины. Именно в этой ситуации каждый член Церкви, будь
            то священник или мирянин, должен определить свое отношение к тому явлению, которое Святейший патриарх
            Алексий очень точно определил как неообновленчество. Если мы с вами именно сейчас не осознаем всю
            пагубность, опасность данного явления, которое задевает самые основы православной церкви, ее традиции,
            которые существуют уже веками, если пойдем на уступки и компромиссы в делах церковного благочестия, то
            это может обернуться для церкви нашей страшными последствиями, сущность которых мы сейчас с вами, может
            быть, полностью не в состоянии осознать. Речь идет о реформации церкви, как это было уже в 20-е годы, во
            времена обновленчества. Поэтому со всей ответственностью должен сказать,
            что <strong>промедление</strong> в определении своего отношения к неообновленчеству <strong>подобно
            смерти. </strong>Мы, конечно, веруем, что никакие силы зла не одолеют Церкви, что она свята и
            непорочна, что она — столп и утверждение Истины. Тем не менее, <strong>многое зависит и от самих
            верующих…</strong></p>
        </div>
    );
};

export default Zaos2;
