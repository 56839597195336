import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Slide from '@mui/material/Slide';
import GroupOrientation from "./GroupOrientation";
import Box from "@mui/material/Box";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Nachalo from "./Pages/1nachalo";
import Zaostrov from "./Pages/2zaostrov";
import NextPreviousComponent from "./NextPreviousComponent";
import Title from "./Pages/0title";
import Zaos1 from "./Pages/3zaos1";
import Zaos2 from "./Pages/4zaos2";
import Zaos3 from "./Pages/5zaos3";
import Zaos4 from "./Pages/6zaos4";
import Zaos5 from "./Pages/7zaos5";
import Zaos6 from "./Pages/8zaos6";
import Zaos7 from "./Pages/9zaos7";
import Zaos8 from "./Pages/10zaos8";
import Zenon from "./Pages/11Zenon";
import Herson from "./Pages/12Herson";
import Tomsk from "./Pages/13Tomsk";
import Ekaterinburg from "./Pages/14Ekaterinburg";
import Culishki from "./Pages/15Culishki";
import WhoNext from "./Pages/16WhoNext";
import AfterEnd from "./Pages/18AfterEnd";
import Endshpil from "./Pages/17Endshpil";
import NextPreviousComponentMobile from "./NextPreviousComponentMobile";
import LongMenu from "./GroupOrientationMobile";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from "@mui/material/Container";

export const pathsWithoutParts = [
    {"name": "/", "number": 0, "caption": "Главная страница"},
    {"name": "/nachalo/", "number": 1, "caption": "Введение"},
    {
        "name": "/zaostrov/",
        "number": 2,
        "caption": "Дело священника Георгия Кочеткова и общины храма Успения Пресвятой Богородицы в Печатника"
    },
    {"name": "/non-licet/", "number": 11, "caption": "«Дело» архимандрита Зинона"},
    {"name": "/fatheradelgeim/", "number": 12, "caption": "«Херсонское дело»"},
    {"name": "/chronologyadelgeim/", "number": 13, "caption": "«Томское дело»"},
    {"name": "/strugglechurch/", "number": 14, "caption": "«Екатеринбургское дело»"},
    {"name": "/tver/", "number": 15, "caption": "«Дело» игумена Мартирия (Багина) и храма Всех святых на Кулишках"},
    {"name": "/krasnousolsk/", "number": 16, "caption": "Кто следующий?"},
    {"name": "/nizhnevartovsk/", "number": 17, "caption": "Заключение"},
    {"name": "/pril/", "number": 18, "caption": "Приложение"}
]
export const paths = [
    {
        "name": "/",
        "element": <Title/>
    }, {
        "name": "/nachalo/",
        "element": <Nachalo/>
    },
    {
        "name": "/zaostrov/",
        "element": <Zaostrov/>
    },
    {
        "name": "/zaos1/",
        "element": <Zaos1/>
    },
    {
        "name": "/zaos2/",
        "element": <Zaos2/>
    },
    {
        "name": "/zaos3/",
        "element": <Zaos3/>
    },
    {
        "name": "/zaos4/",
        "element": <Zaos4/>
    },
    {
        "name": "/zaos5/",
        "element": <Zaos5/>
    },
    {
        "name": "/zaos6/",
        "element": <Zaos6/>
    },
    {
        "name": "/zaos7/",
        "element": <Zaos7/>
    },
    {
        "name": "/zaos8/",
        "element": <Zaos8/>
    },
    {
        "name": "/non-licet/",
        "element": <Zenon/>
    },
    {
        "name": "/fatheradelgeim/",
        "element": <Herson/>
    },
    {
        "name": "/chronologyadelgeim/",
        "element": <Tomsk/>
    },
    {
        "name": "/strugglechurch/",
        "element": <Ekaterinburg/>
    },
    {
        "name": "/tver/",
        "element": <Culishki/>
    },
    {
        "name": "/krasnousolsk/",
        "element": <WhoNext/>
    },
    {
        "name": "/nizhnevartovsk/",
        "element": <Endshpil/>
    },
    {
        "name": "/pril/",
        "element": <AfterEnd/>
    }
]

function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
}

function isMobile() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}


export default function Main(props) {
    if (isMobile()) {
        return (
            <React.Fragment>
                <CssBaseline/>
                <HideOnScroll {...props}>
                    <AppBar>
                        <Typography variant="h6" component="div">
                            <LongMenu></LongMenu>
                        </Typography>
                    </AppBar>
                </HideOnScroll>
                <Toolbar/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    flexWrap: 'nowrap',
                    alignContent: 'top'
                }}>
                    <BrowserRouter initialEntries={['/']} initialIndex={0}>
                        <Container>
                            <div style={{height: "100vh", overflow: "auto", overflowY: "auto"}}>
                                <NextPreviousComponentMobile>
                                </NextPreviousComponentMobile>
                                <Routes>
                                    {paths.map(path => {
                                        return <Route path={path.name} element={path.element}/>
                                    })}
                                    <Route path="*" element={paths[0].element}/>
                                </Routes>
                                <NextPreviousComponentMobile>
                                </NextPreviousComponentMobile>
                            </div>
                        </Container>
                    </BrowserRouter>
                </Box>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <CssBaseline/>
            <HideOnScroll {...props}>
                <AppBar>
                    <Typography variant="h6" component="div">
                        <LongMenu></LongMenu>
                    </Typography>
                </AppBar>
            </HideOnScroll>
            <Toolbar/>
            <table width='1024px' align='center'>
                <tr>
                    <td>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'left',
                            flexWrap: 'nowrap',
                            alignContent: 'top'
                        }}>
                            <BrowserRouter initialEntries={['/']} initialIndex={0}>
                                <div>
                                    <GroupOrientation>
                                    </GroupOrientation>
                                </div>
                                <Container>
                                    <div style={{height: "100vh", overflow: "auto", overflowY: "auto"}}>
                                        <NextPreviousComponent>
                                        </NextPreviousComponent>
                                        <Routes>
                                            {paths.map(path => {
                                                return <Route path={path.name} element={path.element}/>
                                            })}
                                            <Route path="*" element={paths[0].element}/>
                                        </Routes>
                                        <NextPreviousComponent>
                                        </NextPreviousComponent>
                                    </div>
                                </Container>

                            </BrowserRouter>
                        </Box>
                    </td>
                </tr>
            </table>
        </React.Fragment>

    );
}
